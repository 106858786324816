<template>
  <div
    class="payermax-applepay"
    :class="{
      'payermax-applepay_disabled': disabled,
      'payermax-applepay_wide': !isLoading,
    }"
  >
    <ui-button
      v-if="isLoading"
      class="payermax-applepay__default-button"
      ref="defaultButton"
      apple-pay
      aria-label="Apple pay button"
      :is-loading="isLoading || disabled"
      :disabled="isLoading || disabled"
    />
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  watch,
  toRefs,
  nextTick,
  getCurrentInstance,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useElementVisibility } from '@vueuse/core';
import { debounce } from 'lodash';
import api from '@/api';
import UiButton from '@/components/UiButton.vue';

const PAYERMAX_SANDBOX = process.env.VUE_APP_PAYERMAX_SANDBOX_ENV === 'true';
const PAYMENT_STATUS = {
  APPLE_PAY_CANCEL: 'APPLE_PAY_CANCEL',
  APPLY_SUCCESS: 'APPLY_SUCCESS',
  REPEAT_REQUEST: 'REPEAT_REQUEST',
  APPLE_PAY_SESSION_ERROR: 'APPLE_PAY_SESSION_ERROR',
  RETRIEVE_PAYMENT_TOKEN_ERROR: 'RETRIEVE_PAYMENT_TOKEN_ERROR',
  REQUEST_TIMEOUT: 'REQUEST_TIMEOUT',
  PENDING: 'PENDING',
};

const props = defineProps({
  isStaff: Boolean,
  listPayment: Array,
  feeChecked: Boolean,
  currency: String,
  amount: Number,
  groupId: String,
  staffPayoutId: String,
  workplacePayoutId: String,
  amplitudePaymentData: Object,
  backUrl: Object,
  disabled: Boolean,
  minTipsAmount: Number,
  billId: String,
  isTips: {
    type: Boolean,
    default: false,
  },
});

const {
  isStaff,
  listPayment,
  currency,
  amount,
  feeChecked,
  disabled,
  groupId,
  workplacePayoutId,
  amplitudePaymentData,
  backUrl,
  minTipsAmount,
  billId,
  isTips,
} = toRefs(props);

const currentInstance = getCurrentInstance();
const { $amplitude } = currentInstance.appContext.config.globalProperties;

const router = useRouter();
const store = useStore();

const defaultButton = ref(null);
const defaultButtonIsVisible = useElementVisibility(defaultButton);

const payerMax = ref(null);
const paymentData = ref(null);

const parentButton = ref(null);
const childButton = ref(null);

const isLoading = ref(true);

const isBill = computed(() => {
  return Boolean(billId.value);
});

const isQrBillWithTips = computed(() => {
  return isBill.value && isTips.value;
});

const isQrBillWithoutTips = computed(() => {
  return isBill.value && !isTips.value;
});

const isNotBillOrIsBillWithTips = computed(() => {
  return !isBill.value || isQrBillWithTips.value;
});

const feeAmount = computed(() => {
  return store.getters['fee/foundFee'](+(amount.value.toFixed(2)))?.feeAmount;
});

const totalAmount = computed(() => {
  return (feeChecked.value
    ? +(((amount.value || 0) + (feeAmount.value || 0)).toFixed(2))
    : (amount.value || 0)).toFixed(2);
});

const checkInitialization = computed(() => {
  return {
    defaultButtonIsVisible: defaultButtonIsVisible.value,
    feeChecked: feeChecked.value,
    amount: amount.value,
    feeAmount: feeAmount.value,
    isTips: isTips.value || false,
  };
});

const wrongPayload = computed(() => {
  if (isQrBillWithoutTips.value) {
    return false;
  }

  if (feeChecked.value && feeAmount.value === 0) {
    return true;
  }

  return listPayment.value.some((item) => {
    return item.amount === 0;
  });
});

const paymentPayload = computed(() => {
  const tipsDetails = {
    listPayment: listPayment.value,
    currency: currency.value,
    amount: +totalAmount.value,
    feeAmount: feeAmount.value,
    feeChecked: feeChecked.value,
    workplacePayoutId: workplacePayoutId.value,
    groupId: groupId.value,
  };

  if (isQrBillWithTips.value) {
    return {
      billId: billId.value,
      tipsDetails,
    };
  }

  if (isQrBillWithoutTips.value) {
    return {
      billId: billId.value,
    };
  }

  return tipsDetails;
});

const sendEvent = (eventName, eventData) => {
  $amplitude.event(`${isStaff.value ? 'STAFF' : 'RESTAURANT'}_${eventName}`, {
    ...amplitudePaymentData,
    ...eventData,
  });
};

const checkPayment = (res) => {
  if (wrongPayload.value) {
    sendEvent('PAYERMAX_APPLE_PAY_WRONG_PAYMENT', { responseData: res });

    window.location.href = router.resolve({
      name: 'payment-failed',
      query: {
        payermax: true,
      },
    }).href;

    return;
  }

  sendEvent('PAYERMAX_APPLE_PAY_BUTTON_CLICK', { responseData: res });
};

const redirectToStatusPage = ({ status, paymentId }) => {
  window.location.href = router.resolve({
    path: `${backUrl.value.locationId}/${status}`,
    query: {
      ...backUrl.value.query,
      paymentId,
      rate: props.backUrl.rate,
      isPayermaxApplePayPayment: true,
      amount: totalAmount.value,
    },
  }).href;
};

const createButton = async () => {
  await nextTick();

  childButton.value = document.querySelector('.payermax-applepay__button');

  if (!childButton.value) {
    childButton.value = document.createElement('div');
    childButton.value.classList.add('payermax-applepay__button');
  }

  parentButton.value = document.querySelector('.payermax-applepay');
  parentButton.value.appendChild(childButton.value);
};

const destroyButton = async () => {
  await nextTick();

  if (!parentButton.value || !childButton.value) {
    return;
  }

  parentButton.value.removeChild(childButton.value);
  childButton.value = null;
};

const loadKeys = async () => {
  const isInvalid = isNotBillOrIsBillWithTips.value
    ? amount.value <= minTipsAmount || !totalAmount.value
    : false;

  if (isInvalid) {
    return;
  }

  isLoading.value = true;

  try {
    const tips = {
      currency: currency.value,
      amount: +totalAmount.value,
    };

    let payload = tips;

    if (isQrBillWithTips.value) {
      payload = {
        billId: billId.value,
        tips,
      };
    }

    if (isQrBillWithoutTips.value) {
      payload = {
        billId: billId.value,
      };
    }

    const { data } = await api.getPayermaxAppleSession(payload, isBill.value);

    paymentData.value = data;

    sendEvent('PAYERMAX_LOAD_KEYS_SUCCESS', {
      reqestData: {
        currency: currency.value,
        amount: +totalAmount.value,
      },
      responseData: data,
    });
  } catch (e) {
    sendEvent('PAYERMAX_LOAD_KEYS_ERROR', {
      reqestData: {
        currency: currency.value,
        amount: +totalAmount.value,
      },
      responseData: null,
    });

    destroyButton();
  }
};

const initialize = async () => {
  payerMax.value = window.PMdropin;

  await createButton();
  await loadKeys();

  if (!paymentData.value) {
    return;
  }

  const applepay = payerMax.value.create('applepay', {
    clientKey: paymentData.value?.clientKey,
    sessionKey: paymentData.value?.sessionKey,
    payButtonStyle: 'width:370px;height:56px;border-radius:10px;background-color:transparent;',
    sandbox: PAYERMAX_SANDBOX,
  });

  applepay.mount('.payermax-applepay__button');

  applepay.on('ready', () => {
    // Only setTimeout with 400ms fixes ui problem with loading
    const timeoutId = setTimeout(() => {
      isLoading.value = false;
      if (childButton.value) {
        childButton.value.classList.add('payermax-applepay__button_visible');
      }
      clearTimeout(timeoutId);
    }, 700);
  });

  applepay.on('payButtonClick', (res) => {
    if (disabled.value) {
      return;
    }

    checkPayment(res);

    applepay.emit('setDisabled', true);

    applepay.emit('canMakePayment')
      .then(async (response) => {
        applepay.emit('setDisabled', false);

        sendEvent('PAYERMAX_APPLE_PAY_CAN_MAKE_PAYMENT', {
          responseData: response,
        });

        switch (response.code) {
          case PAYMENT_STATUS.REPEAT_REQUEST:
          case PAYMENT_STATUS.APPLE_PAY_SESSION_ERROR:
          case PAYMENT_STATUS.RETRIEVE_PAYMENT_TOKEN_ERROR:
          case PAYMENT_STATUS.REQUEST_TIMEOUT:
            applepay.emit('payFail');

            redirectToStatusPage({
              status: 'error',
              paymentId: null,
            });
            break;
          case PAYMENT_STATUS.APPLY_SUCCESS:
            try {
              const { data } = await api.createPayermaxApplePayment({
                ...paymentPayload.value,
                sessionKey: paymentData.value?.sessionKey,
                customerCardToken: response.data.paymentToken,
              }, isBill.value);

              sendEvent('PAYERMAX_APPLE_PAY_MAKE_PAYMENT', {
                requestData: {
                  ...paymentPayload.value,
                  sessionKey: paymentData.value?.sessionKey,
                  customerCardToken: response.data.paymentToken,
                },
                responseData: data,
              });

              if (data) {
                if (data.status === PAYMENT_STATUS.PENDING) {
                  applepay.emit('paySuccess');

                  redirectToStatusPage({
                    status: 'success',
                    paymentId: data.id,
                  });
                } else {
                  applepay.emit('payFail');
                }
              }
            } catch (e) {
              applepay.emit('payFail');

              sendEvent('PAYERMAX_APPLE_PAY_MAKE_PAYMENT_ERROR', {
                requestData: {
                  sessionKey: paymentData.value?.sessionKey,
                },
                responseData: e,
              });
            } finally {
              applepay.emit('setDisabled', false);
            }
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        sendEvent('PAYERMAX_APPLE_PAY_CAN_MAKE_PAYMENT_ERROR', {
          requestData: {
            sessionKey: paymentData.value?.sessionKey,
          },
          responseData: e?.message,
        });

        applepay.emit('setDisabled', false);
      });
  });
};

const debouncedWatch = debounce((payload) => {
  if (isNotBillOrIsBillWithTips.value && !payload.amount) {
    destroyButton();
    isLoading.value = false;
    return;
  }

  const isValid = isNotBillOrIsBillWithTips.value
    ? payload.amount
    : true;

  if (isValid) {
    destroyButton();
    initialize();
  }
}, 800);

const reloadWatch = (payload) => {
  if (!isLoading.value) {
    destroyButton();
    isLoading.value = true;
  }

  debouncedWatch(payload);
};

watch(checkInitialization, reloadWatch, { deep: true });
</script>

<style lang="scss">
.payermax-applepay {
  width: 100%;
  overflow: hidden;

  &_wide {
    width: 110%;
  }

  &_disabled {
    position: relative;
    opacity: 0.5;

    ::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      cursor: default;
    }
  }

  &__default-button {
    width: 100%;
    max-width: 370px;
    height: 56px;
    margin: 16px auto;
  }

  &__button {
    height: 0;
    overflow: hidden;
    visibility: hidden;

    &_visible {
      width: 100%;
      height: 88px;
      visibility: visible;
    }
  }
}
</style>
